import React from 'react'
import { navigate } from 'gatsby'

const Teacher = props => {

  function showTeachers(){
    navigate('/booking-teacher?v='+window.localStorage.getItem('appVersion'),{state:{subject:props.subject, level: props.level, tutors: props.tutors}});
  }

  function selectTeacher(id){
    window.localStorage.setItem('selectedTeacher',id);
    navigate(-1)
  }

  if(!props.teacher){
    props.teacher = {};
  }

  return (
    <div className='teacher-card card' onClick={()=>{if(props.select) selectTeacher(props.teacher.id)}}>
      <div className='row gx-3 align-items-center'>
        <div className="col-auto">
          <div className="avatar">
            <i className="icon-Profile icon" style={{ margin: 'auto', fontSize: 30 }} />
          </div>
        </div>
        <div className="col">
          <h6><strong>{props.teacher.firstname} {props.teacher.lastname}</strong></h6>
          <p className="desc mb-0">{props.teacher.position} Tutor</p>
        </div>
        <div className="col-auto d-flex  align-items-center">
          <i className="icon icon-Star_Fill"></i>
          <span className="h6 mb-0"><strong>4.8</strong></span>
        </div>
        <div>
        {/*{!props.select && (<i
            className='icon icon-Right ml-auto a-self-center'
            style={{ fontSize: 40, cursor: 'pointer' }}
            onClick={()=>showTeachers()}
        />)}*/}
        </div>      
      </div>        
    </div>
  )
}

export default Teacher
